<template>
	<div class="articles__wordpress">
		<v-text-field v-model="search" class="search" append-icon="mdi-magnify" label="Rechercher un article" single-line hide-details></v-text-field>
		<br>
		<div class="header header__draft">
			<h4>Articles non publiés sur le site</h4>
			<v-btn color="white--text" class="elevation-0" @click="$router.push({ path: '/user/' + user.id + '/add-article'})" tile>
				<v-icon color="white">
					mdi-pencil-plus-outline
				</v-icon>
				Rédiger un article
			</v-btn>
		</div>
		<v-data-table :headers="headers" :items="articlesDraft" :items-per-page="5" :search="search" :sort-by="['created']" :sort-desc="[true]" class="elevation-1">
			<template v-slot:item.created="{ item }">
				{{ formatedDate(item.created) }}
			</template>
			<template v-slot:item.titre="{ item }">
				<span v-html="renderingTitle(item.titre)"></span>
			</template>
			<template v-slot:item.content="{ item }">
				<p v-html="renderingText(item.content)"></p>
			</template>
			<template v-slot:item.actions="{ item }">
				<v-icon class="mr-2" @click="$router.push({path: '/article/' + item.id})" title="Modifier">
					mdi-square-edit-outline
				</v-icon>
				<v-icon class="mr-2" @click="$router.push({path: '/article/' + item.id + '/send-wp'})" title="Publier l'article">
					mdi-send
				</v-icon>
			</template>
		</v-data-table>
		<div class="header header__publish">
			<h4>Articles publiés sur le site</h4>			
		</div>
		<v-data-table  :headers="headers" :items="articles" :items-per-page="5" :search="search" :sort-by="['created']" :sort-desc="[true]" class="elevation-1">
			<template v-slot:item.created="{ item }">
				{{ formatedDate(item.created) }}
			</template>
			<template v-slot:item.titre="{ item }">
				<span v-html="renderingTitle(item.titre)"></span>
			</template>
			<template v-slot:item.content="{ item }">
				<p v-html="renderingText(item.content)"></p>
			</template>
			<template v-slot:item.actions="{ item }">
				<v-icon class="mr-2" @click="$router.push({path: '/article/' + item.id})">
					mdi-eye-outline
				</v-icon>
			</template>
		</v-data-table>
	</div>
</template>

<script>
import { mapActions } from 'vuex';
import { utils } from '../../utils.js'

export default {
	name: 'articles',
	props: ['user'],
	data() {
		return {
			search: '',
			articles: [],
			articlesDraft: [],
			toDelete: {},
			loading: false,
			loadingDelete: false,
			dialog: false,
			headers: [
				{ text: 'Création', value: 'created' },
				{ text: 'Titre', value: 'titre' },
				{ text: 'Contenu', value: 'content', sortable: false },
				{ text: '', value: 'actions', sortable: false }
			],
		}
	},
	methods: {
		...mapActions("global", [
			"getSiteArticlesAction"
		]),
		formatedDate(date) {
			return utils.tools.formatedDate(date)
		},
		renderingTitle(text) {
			let rendered = text.substring(0, 20)
            if (text.length > 20) {
                rendered = rendered + '[...]'
            }
            return rendered
		},
		renderingText(text) {
            let rendered = text.substring(0, 100)
            if (text.length > 100) {
                rendered = rendered + '[...]'
            }
            return rendered
        },
		loadArticles() {
			this.articles = []
			this.articlesDraft = []

			const _this = this;
			this.loading = true;
			this.getSiteArticlesAction(this.user.site.id)
			.then(res => {
				res.data.forEach(function(item){
					if (item.status == 'publish') {
						_this.articles.push(item)
					} else {
						_this.articlesDraft.push(item)
					}
				})
			})
			.finally(() => {
				_this.loading = false
			})
		},
		addArticle() {
			this.$router.push({ name: 'addArticle', query: { user: this.user } });
		}
	},
	created() {
		this.loadArticles()
	}
}
</script>
<style lang="scss" scoped>
.articles__wordpress {
	.search {
		padding-left: 15px;
		padding-right: 15px;
	}

	.header {
		display: flex;
		align-items: center;
		margin-bottom: 15px;
		margin-left: 15px;
		padding: 10px 0px;

		i {
			margin-right: 5px;
		}

		&.header__publish {
			margin-top: 20px;
		}
	}

	h4 {
		width: 100%;
		margin: 0;
	}

	p {
		margin: 0 !important
	}
}
</style>
<style>
p {
	margin: 0 !important;
}
</style>