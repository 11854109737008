<template>
	<v-card class="website__card elevation-0" color="transparent">
		<h4>Site</h4>
		<v-form
			ref="form"
			v-model="valid"
			lazy-validation
		>
			<v-list class="elevation-0" color="transparent" two-line>
				<v-list-item>
					<v-list-item-icon>
						<v-icon color="blue lighten-3">
							mdi-web
						</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-text-field v-model="user.site.url" :rules="urlRules" label="URL"></v-text-field>
					</v-list-item-content>
				</v-list-item>
				<v-list-item>
					<v-list-item-icon>
						<v-icon color="blue lighten-3">
							mdi-api
						</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-text-field v-model="user.site.url_api" :rules="urlapiRules" label="URL de l'API"></v-text-field>
					</v-list-item-content>
				</v-list-item>
				<v-list-item>
					<v-list-item-icon>
						<v-icon color="blue lighten-3">
							mdi-account
						</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-text-field v-model="user.site.login" :rules="loginRules" label="Identifiant WordPress"></v-text-field>
					</v-list-item-content>
				</v-list-item>
				<v-list-item>
					<v-list-item-icon>
						<v-icon color="blue lighten-3">
							mdi-key-variant
						</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-text-field v-model="user.site.password" :rules="passwordRules" label="Mot de passe WordPress"></v-text-field>
					</v-list-item-content>
				</v-list-item>
				<v-divider inset></v-divider>
			</v-list>
			<div class="text-center">
				<v-btn color="green" class="white--text save__btn elevation-0" @click="validate" :loading="loading" :disabled="!valid" tile>Enregistrer</v-btn>
			</div>
		</v-form>
	</v-card>	
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import { utils } from '../../utils.js'

export default {
	name: 'site',
	props: ['user'],
	data() {
		return {
			valid: false,
			urlRules: [
				v => !!v || 'Champs requis'
			],
			urlapiRules: [
				v => !!v || 'Champs requis'
			],
			loginRules: [
				v => !!v || 'Champs requis'
			],
			passwordRules: [
				v => !!v || 'Champs requis'
			],
			loading: false
		}
	},
	methods: {
		...mapActions("global", [
			"addSiteAction",
			"updateSiteAction"
		]),
		...mapMutations("global", [
			"addSuccess",
			"addErrors"
		]),
		validate () {
			if (this.$refs.form.validate() == false) {
				return
			}

			if (this.user.site.id > 0) {
				this.update()
			} else {
				this.add()
			}
		},
		add() {
			const _this = this;
			this.loading = true
			this.addSiteAction({
				url: _this.user.site.url,
				url_api: _this.user.site.url_api,
				login: _this.user.site.login,
				password: _this.user.site.password,
				user: _this.user.id
			})
			.then((res) => {
				_this.user.site.id = res.data.id
				_this.addSuccess('Site ajouté')
			})
			.catch(err => {
				_this.addErrors(utils.ajax.normalize_error(err))
			})
			.finally(() => {
				_this.loading = false
			})
		},
		update() {
			const _this = this;
			this.loading = true
			this.updateSiteAction({
				site: _this.user.site.id,
				url: _this.user.site.url,
				url_api: _this.user.site.url_api,
				login: _this.user.site.login,
				password: _this.user.site.password
			})
			.then(() => {
				_this.addSuccess('Les informations du site ont été modifiées.')
			})
			.catch(err => {
				_this.addErrors(utils.ajax.normalize_error(err))
			})
			.finally(() => {
				_this.loading = false
			})
		}
	}
}
</script>

<style lang="scss" scoped>
.website__card {
	position: relative;
	border-radius: 0 !important;
	padding-bottom: 15%;
	h4 {
		padding: 10px 20px;
		color: white;
		font-weight: normal;
		background: #272727;
	}
	.save__btn {
		margin: 0 auto;
	}
	.v-list-item {
		height: 64px;
	}
}
</style>