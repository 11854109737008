<template>
	<div class="annuaires__wordpress">
		<v-text-field v-model="search" class="search" append-icon="mdi-magnify" label="Rechercher un annuaire" single-line hide-details></v-text-field>
		<br>
		<div class="header header__draft">
			<h4>Annuaires demandés</h4>
			<v-btn color="white--text" class="elevation-0" @click="$router.push({ path: '/user/' + user.id + '/add-annuaire-inscription'})" tile>
				<v-icon color="white">
					mdi-book-open
				</v-icon>
				Inscrire le site à un annuaire
			</v-btn>
		</div>
		<v-data-table :headers="headers" :items="annuaireInscriptionsDraft" :items-per-page="5" :search="search" :sort-by="['created']" :sort-desc="[true]" class="elevation-1">
			<template v-slot:item.created="{ item }">
				{{ formatedDate(item.created) }}
			</template>
			<template v-slot:item.name="{ item }">
				<a :href="getBaseUrl(item.url)" target="_blank"><span v-html="renderingTitle(item.name)"></span></a>
			</template>
			<template v-slot:item.actions="{ item }">
				<v-icon class="mr-2" @click="$router.push({path: '/annuaire-inscription/' + item.id})" title="Modifier">
					mdi-square-edit-outline
				</v-icon>
				<v-icon class="mr-2" @click="$router.push({path: '/annuaire-inscription/' + item.id + '/send'})" title="Publier le site sur l'annuaire">
					mdi-send
				</v-icon>
			</template>
		</v-data-table>
		<div class="header header__publish">
			<h4>Annuaires sur lesquels le site est publié</h4>			
		</div>
		<v-data-table  :headers="headers" :items="annuaireInscriptions" :items-per-page="5" :search="search" :sort-by="['created']" :sort-desc="[true]" class="elevation-1">
			<template v-slot:item.created="{ item }">
				{{ formatedDate(item.created) }}
			</template>
			<template v-slot:item.name="{ item }">
				<a :href="getBaseUrl(item.url)" target="_blank"><span v-html="renderingTitle(item.name)"></span></a>
			</template>
			<template v-slot:item.actions="{ item }">
				<v-icon class="mr-2" @click="$router.push({path: '/annuaire-inscription/' + item.id})">
					mdi-eye-outline
				</v-icon>
			</template>
		</v-data-table>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import { utils } from '../../utils.js'

export default {
	name: 'annuaireInscription',
	props: ['user'],
	data() {
		return {
			search: '',
			annuaireInscriptions: [],
			annuaireInscriptionsDraft: [],
			toDelete: {},
			loading: false,
			loadingDelete: false,
			dialog: false,
			headers: [
				{ text: 'Création', value: 'created' },
				{ text: 'Annuaire', value: 'name' },
				{ text: 'Thème', value: 'theme' },
				{ text: '', value: 'actions', sortable: false }
			],
		}
	},
	computed: {
		...mapGetters("global", [
			"getToken"
		])
	},
	methods: {
		formatedDate(date) {
			return utils.tools.formatedDate(date)
		},
        getBaseUrl(url) {
            let urlObject = new URL(url);
            return urlObject.origin
        },
		renderingTitle(text) {
			let rendered = text.substring(0, 20)
            if (text.length > 20) {
                rendered = rendered + '[...]'
            }
            return rendered
		},
		loadAnnuaireInscriptions() {
			const _this = this
			this.annuaireInscriptions = []
			this.annuaireInscriptionsDraft = []

			this.user.site.inscriptions.forEach(function(item){
				const annuaireInscription = {
					id: item.id,
					created: item.created,
					name: item.annuaire.name,
					url: item.annuaire.url,
					theme: item.annuaire.theme.name
				}

				if (item.status == 'publish') {
					_this.annuaireInscriptions.push(annuaireInscription)
				} else {
					_this.annuaireInscriptionsDraft.push(annuaireInscription)
				}
			})
		},
		addArticle() {
			this.$router.push({ name: 'addArticle', query: { user: this.user } });
		}
	},
	created() {
		this.loadAnnuaireInscriptions()
	}
}
</script>
<style lang="scss" scoped>
.annuaires__wordpress {
	.search {
		padding-left: 15px;
		padding-right: 15px;
	}

	.header {
		display: flex;
		align-items: center;
		margin-bottom: 15px;
		margin-left: 15px;
		padding: 10px 0px;

		i {
			margin-right: 5px;
		}

		&.header__publish {
			margin-top: 20px;
		}
	}

	h4 {
		width: 100%;
		margin: 0;
	}

	p {
		margin: 0 !important
	}
}
</style>
<style>
p {
	margin: 0 !important;
}
</style>