<template>
	<div class="User__view">
		<div class="header">
			<h2>{{user.cab}} <span>-</span> {{ user.login }} <span>- {{ user.email }}</span></h2>
			<v-btn color="white--text" class="elevation-0" @click="$router.push({path: '/user/' + $route.params.userId + '/edit'})" tile>
				<v-icon color="white">
					mdi-account-edit
				</v-icon>
				Modifier l'utilisateur
			</v-btn>
			<v-btn v-if="user.site.id > 0" color="white--text" class="elevation-0" @click="$router.push({path: '/site/' + user.site.id + '/wp-article'})" tile>
				<v-icon color="white">
					mdi-wordpress
				</v-icon>
				Articles du site
			</v-btn>
			<v-btn v-if="user.id > 0" color="white--text" class="elevation-0" @click="$router.push({path: '/user/' + user.id + '/log'})" tile>
				<v-icon color="white">
					mdi-format-list-bulleted
				</v-icon>
				Logs
			</v-btn>
			<v-btn class="white--text elevation-0" :loading="loading" @click="beforeDelete()" icon tile>
				<v-icon color="red lighten-1">
					mdi-trash-can-outline
				</v-icon>
			</v-btn>
		</div>
		<div class="displayer">
			<div class="left">
				<website :user="user"></website>
			</div>
			<div class="left">
				<company :user="user"></company>
			</div>
			<div v-if="user.site.id > 0" class="right">
				<v-toolbar
					dark
					flat
				>
					<template>
						<v-tabs
							v-model="ongletTableau"
							centered
							slider-color="yellow"
						>
							<v-tab
								href="#tab-articles"
							>
								Articles
							</v-tab>
							<v-tab
								href="#tab-annuaires"
							>
								Annuaires
							</v-tab>
						</v-tabs>
					</template>
				</v-toolbar>
				<v-tabs-items v-model="ongletTableau">
					<v-tab-item
						value="tab-articles"
					>
						<v-card flat>
							<articles :user="user"></articles>
						</v-card>
					</v-tab-item>
					<v-tab-item
						value="tab-annuaires"
					>
						<annuaireInscription :user="user"></annuaireInscription>
					</v-tab-item>					
				</v-tabs-items>
			</div>
		</div>
		<v-dialog v-model="dialog" width="300">
			<v-card>
				<v-card-text style="padding-top: 20px">
					<h3 class="text-center">Supprimer l'utilisateur et le site de Maax ?</h3>
				</v-card-text>
				<v-card-actions>
					<v-btn color="primary" text @click="dialog = false">
						Annuler
					</v-btn>
					<v-spacer></v-spacer>
					<v-btn color="red darken-3" :loading="loading" text @click="deleteUser()">
						Supprimer
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex';
import { utils } from '../utils.js'
import website from '../components/user/website.vue'
import company from '../components/user/company.vue'
import articles from '../components/user/articles.vue'
import annuaireInscription from '../components/user/annuaireInscription.vue'

export default {
	name: 'User',
	components: {
		website,
		company,
		articles,
		annuaireInscription
	},
	data() {
		return {
			ongletTableau: 'tab-articles',
			user: {
				id: 0,
				cab: '',
				login: '',
				email: '',
				site: {
					id: 0,
					url: '',
					url_api: '',
					login: '',
					password: '',
					inscriptions: []
				},
				company: {
					id:   0,
					nom:  '',
					tel:  '',
					adr:  '',
					cp:   '',
					city: '',
					theme: {
						id: null
					}
				}
			},
			loading: false,
			dialog: false,
			headers: [
				{ text: 'url', value: 'url' },
				{ text: 'theme', value: 'theme.name', sortable: false },
				{ text: '', value: 'actions', sortable: false }
			],
		}
	},
	computed: {
		...mapGetters("user", [
			"getSearch"
		]),
		...mapGetters("global", [
			"getToken"
		])
	},
	methods: {
		...mapActions("user", [
			"searchUserAction",
			"deleteUserAction",
			"getUserAction"
		]),
		...mapMutations("user", [
			"setSearchResult"
		]),
		...mapMutations("global", [
			"addSuccess",
			"addErrors"
		]),
		retour() {
			this.$router.push({path: '/'})
		},
		beforeDelete() {
			this.dialog = true;
		},
		deleteUser() {
			const _this = this;
			this.loading = true;
			this.deleteUserAction(this.user.id)
			.then(() => {
				_this.addSuccess("L'utilisateur a été supprimé.")
				_this.searchUserAction(_this.getSearch)
				.then(res => {
					_this.setSearchResult(res.data.results)
				})
				_this.retour()
				_this.loading = false;
			})
			.catch(err => {
				_this.addErrors(utils.ajax.normalize_error(err))
			})
		},
		loadUser() {
			const _this = this
			this.getUserAction(this.$route.params.userId)
			.then(function (res) {
				_this.user.id = res.data.id
				_this.user.cab = res.data.cab
				_this.user.login = res.data.login
				_this.user.email = res.data.email

				if(res.data.site != null)
				{
					_this.user.site.id = res.data.site.id
					_this.user.site.url = res.data.site.url
					_this.user.site.url_api = res.data.site.url_api
					_this.user.site.login = res.data.site.login
					_this.user.site.password = res.data.site.password
					_this.user.site.inscriptions = res.data.site.inscriptions
				}

				if(res.data.company != null)
				{
					_this.user.company.id = res.data.company.id
					_this.user.company.nom = res.data.company.nom
					_this.user.company.tel = res.data.company.tel
					_this.user.company.adr = res.data.company.adr
					_this.user.company.cp = res.data.company.cp
					_this.user.company.city = res.data.company.city
					_this.user.company.theme.id = res.data.company.theme.id 
				}
			})
			.catch(() => {
				_this.retour()
			})
		},
	},
	created () {
		this.loadUser()
	}
}
</script>

<style lang="scss" scoped>
	.User__view {
		width: 100%;
		.header {
			display: flex;
			align-items: center;
			padding: 20px;
			border-bottom: 1px solid #dbdbdb;
			h2 {
				font-weight: normal;
				margin-bottom: 20px;
				width: 100%;
				margin: 0;
				span {
					font-size: 16px;
					color: #969696;
				}
			}

			button {
				margin-right: 10px;
			}
		}
		.main{
			display: flex;
			align-items: center;
			padding: 20px;
			h2 {
				font-weight: normal;
				margin-bottom: 20px;
				width: 100%;
				margin: 0;
				span {
					font-size: 16px;
					color: #969696;
				}
			}
		}
		h4 {
			margin-top: 50px;
		}
		.displayer {
			display: flex;
			width: 100%;
			.left {
				width: 25%;
				border-right: 1px solid #dbdbdb;
				height: 100vh;
			}
			.right {
				width: 75%;
				padding: 1% 2%;
			}
		}
	}
</style>