<template>
	<v-card class="company__card elevation-0" color="transparent">
		<h4>Entreprise</h4>
		<v-form
			ref="form"
			v-model="valid"
			lazy-validation
		>
			<v-list class="elevation-0" color="transparent" two-line>
				<v-list-item>
					<v-list-item-icon>
						<v-icon color="blue lighten-3">
							mdi-domain
						</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-text-field v-model="user.company.nom" :rules="nomRules" label="Nom"></v-text-field>
					</v-list-item-content>
				</v-list-item>
				<v-list-item>
					<v-list-item-icon>
						<v-icon color="blue lighten-3">
							mdi-phone
						</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-text-field v-model="user.company.tel" :rules="telRules" label="Téléphone"></v-text-field>
					</v-list-item-content>
				</v-list-item>
				<v-list-item>
					<v-list-item-icon>
						<v-icon color="blue lighten-3">
							mdi-google-maps
						</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-text-field v-model="user.company.adr" :rules="adrRules" label="Adresse"></v-text-field>
					</v-list-item-content>
				</v-list-item>
				<v-list-item>
					<v-list-item-icon>
						<v-icon color="blue lighten-3">
							mdi-numeric
						</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-text-field v-model="user.company.cp" :rules="cpRules" label="Code postal"></v-text-field>
					</v-list-item-content>
				</v-list-item>
				<v-list-item>
					<v-list-item-icon>
						<v-icon color="blue lighten-3">
							mdi-city
						</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-text-field v-model="user.company.city" :rules="cityRules" label="Ville"></v-text-field>
					</v-list-item-content>
				</v-list-item>
				<v-list-item>
					<v-list-item-icon>
						<v-icon color="blue lighten-3">
							mdi-shape
						</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-select
							v-model="user.company.theme.id"
							:items="themes"
							filled
							item-text="name"
							item-value="id"
							:rules="themeRules"
							label="Thème"
						></v-select>
					</v-list-item-content>
				</v-list-item>
				<v-divider inset></v-divider>
			</v-list>
			<div class="text-center">
				<v-btn color="green" class="white--text save__btn elevation-0" @click="validate()" :loading="loading" :disabled="!valid" tile>Enregistrer</v-btn>
			</div>
		</v-form>
	</v-card>	
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { utils } from '../../utils.js'

export default {
	name: 'company',
	props: ['user'],
	data() {
		return {
			valid: false,
			nomRules: [
				v => !!v || 'Champs requis'
			],
			telRules: [
				v => !!v || 'Champs requis'
			],
			adrRules: [
				v => !!v || 'Champs requis'
			],
			cpRules: [
				v => !!v || 'Champs requis'
			],
			cityRules: [
				v => !!v || 'Champs requis'
			],
			themeRules: [
				v => !!v || 'Champs requis'
			],
			themes: [],
			loading: false
		}
	},
	computed: {
		...mapGetters("user", [
			"getSelectedUser"
		]),
	},
	methods: {
		...mapActions("global", [
			"addCompanyAction",
			"updateCompanyAction",
			"getThemesAction"
		]),
		...mapMutations("global", [
			"addSuccess",
			"addErrors"
		]),
		validate () {
			if (this.$refs.form.validate() == false) {
				return
			}

			if (this.user.company.id > 0) {
				this.update()
			} else {
				this.add()
			}
		},
		add() {
			const _this = this;
			this.loading = true

			this.addCompanyAction({
				"theme": _this.user.company.theme.id,
				"nom": _this.user.company.nom,
				"tel": _this.user.company.tel,
				"adr": _this.user.company.adr,
				"cp": _this.user.company.cp,
				"city": _this.user.company.city,
				"user": _this.user.id
			})
			.then((res) => {
				_this.user.company.id = res.data.id
				_this.addSuccess('Entreprise ajoutée')
			})
			.catch(err => {
				_this.addErrors(utils.ajax.normalize_error(err))
			})
			.finally(() => {
				_this.loading = false
			})
		},
		update() {
			const _this = this;
			this.loading = true
			this.updateCompanyAction({
				"company": _this.user.company.id,
				"theme": _this.user.company.theme.id,
				"nom": _this.user.company.nom,
				"tel": _this.user.company.tel,
				"adr": _this.user.company.adr,
				"cp": _this.user.company.cp,
				"city": _this.user.company.city
			})
			.then(() => {
				_this.addSuccess('Les information de l\'entreprise ont été modifiées.')
			})
			.catch(err => {
				_this.addErrors(utils.ajax.normalize_error(err))
			})
			.finally(() => {
				_this.loading = false
			})
		},
		loadThemes() {
			const _this = this
			this.getThemesAction()
			.then(res => {
				_this.themes = res.data
			})
		}
	},
	created() {
		this.loadThemes()
	}
}
</script>

<style lang="scss" scoped>
.company__card {
	position: relative;
	border-radius: 0 !important;
	padding-bottom: 15%;
	h4 {
		padding: 10px 20px;
		color: white;
		font-weight: normal;
		background: #272727;
	}
	.save__btn {
		margin: 0 auto;
	}
	.v-list-item {
		height: 64px;
	}
}
</style>